.loader_container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255,255,255,0.5);
  z-index: 10000;
}
.loader {
  animation: spin 1s infinite linear;
  border: solid 2vmin transparent;
  border-radius: 50%;
  border-right-color: #5011CC;
  border-top-color: #5011CC;
  box-sizing: border-box;
  height: 20vmin;
  left: calc(50% - 10vmin);
  position: fixed;
  top: calc(50% - 10vmin);
  width: 20vmin;
  z-index: 1;
}
.loader:before {
  animation: spin 2s infinite linear;
  border: solid 2vmin transparent;
  border-radius: 50%;
  border-right-color: #5B4BDB;
  border-top-color: #5B4BDB;
  box-sizing: border-box;
  content: "";
  height: 16vmin;
  left: 0;
  position: absolute;
  top: 0;
  width: 16vmin;
}
.loader:after {
  animation: spin 3s infinite linear;
  border: solid 2vmin transparent;
  border-radius: 50%;
  border-right-color: #5E6DF2;
  border-top-color: #5E6DF2;
  box-sizing: border-box;
  content: "";
  height: 12vmin;
  left: 2vmin;
  position: absolute;
  top: 2vmin;
  width: 12vmin;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}